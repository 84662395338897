<template>
  <b-card title="Detail Warranty Registration">
    <b-modal v-model="loadingWarranty" centered no-close-on-backdrop hide-footer hide-header>
      <div class="d-flex flex-column align-items-center justify-content-center py-3">
        <b-spinner medium variant="primary" label="Spinning"></b-spinner>
        <p class="mt-3">Fetch Warranty Registration Info</p>
      </div>
    </b-modal>

    <b-container class="px-0 mx-0">
        <b-row>
        <b-col cols="12">
          <label>Registration Type</label>
          {{warrantyData.registerType}}
          <!-- <b-form-select
            v-model="warrantyData.registerType"
            :options="registerOptions"
            disabled>
          </b-form-select> -->
        </b-col>

        <b-col cols="12" v-if="warrantyData.registerType === 'airpur'">
          <label>Purchase Date</label>
          <b-form-datepicker v-model="warrantyData.purchaseDate" disabled></b-form-datepicker>
        </b-col>
        </b-row>

        <b-row class="section-data">
          <h5>Customer Info</h5>

        <b-col cols="12">
          <label> Salutation</label>
              <b-form-select
                v-model="warrantyData.salutation"
                :options="listSalutation">
              </b-form-select>
        </b-col>
        

        <b-col cols="12" v-if="warrantyData.customerType === 'Company'">
          <label>Company Name</label>
          <b-form-input v-model="warrantyData.companyName" placeholder="Company Name"></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>First Name</label>
          <b-form-input v-model="warrantyData.nameFirst" placeholder="Customer Name"></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Last Name</label>
          <b-form-input v-model="warrantyData.nameLast" placeholder="Customer Name"></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Contact Number</label>
          <b-form-input v-model="warrantyData.phone" placeholder="Customer Phone"></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Email</label>
          <b-form-input v-model="warrantyData.email" placeholder="Email"></b-form-input>
        </b-col>
        </b-row>

        <b-row class="section-data">
          <h5>Customer Address</h5>

        <b-col cols="12">
          <label>Postal Code</label>
          <b-form-input v-model="warrantyData.postal" placeholder="Postal"></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Block No</label>
          <b-form-input v-model="warrantyData.address" placeholder="Customer Address"></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Road Name</label>
          <b-form-input v-model="warrantyData.address2" placeholder="Customer Address"></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Unit No</label>
          <b-form-input v-model="warrantyData.address3" placeholder="Customer Address"></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Building Name</label>
          <b-form-input v-model="warrantyData.buildingName" placeholder="Building Name"></b-form-input>
        </b-col>
        </b-row>

        <b-row class="section-data">
          <h5>Installation Info</h5>
        

        <b-col cols="12" v-if="warrantyData.registerType === 'aircon'">
          <label>Installer Name</label>
          <b-form-input v-model="warrantyData.installerName" placeholder="Installer Name"></b-form-input>
        </b-col>

        <b-col cols="12" class="hidden">
          <label>Installer Address</label>
          <b-form-input v-model="warrantyData.installerAddress" placeholder="Installer Address"></b-form-input>
        </b-col>

        <b-col cols="12" v-if="warrantyData.registerType === 'aircon'">
          <label>Installation Date</label>
          <b-form-datepicker v-model="warrantyData.installationDate"></b-form-datepicker>
        </b-col>
        </b-row>
        
        <b-row>
        <b-col cols="12">
          <label>Promo Code</label>
          <b-form-input v-model="warrantyData.promoCode" placeholder="Promo Code"></b-form-input>
        </b-col>
        </b-row>

        <b-col cols="12" class="my-2 section-data">
          <h5>Product to be registered</h5>
          
          <b-table
            :items="warrantyData.items"
            :fields="fields"
            responsive
          >
            <template #cell(generalWarrantyPeriodEnd)="row">
              {{ row.item.generalWarrantyPeriodEnd | formatDate}}
            </template>

            <template #cell(compressorWarrantyPeriodEnd)="row">
              {{ row.item.compressorWarrantyPeriodEnd | formatDate}}
            </template>

            <!-- <template #cell(actions)="row">
              <div class="d-flex">
                <b-button variant="danger" size="sm" :disabled="!(warrantyData.registerStatus !== 'Registered')" @click="confirmToDeleteItem(row)" class="mr-1">
                  <b-icon icon="trash"></b-icon>
                </b-button>

                <b-button variant="danger" size="sm" :disabled="!(warrantyData.registerStatus !== 'Registered')" @click="resetItemStatus(row.item._id)">
                  <b-icon icon="arrow-clockwise"></b-icon>
                </b-button>
              </div>
            </template> -->
          </b-table>
        </b-col>
     

      <!-- <div class="mt-6 mb-6 d-flex flex-row-reverse">
        <b-button class="ml-1" variant="danger" :disabled="!(warrantyData.registerStatus !== 'Registered')" @click="deletePaperlessWarranty">Delete</b-button>
        <b-button variant="success" :disabled="!(warrantyData.registerStatus !== 'Registered') || onSubmit" @click="process">
          <template v-if="onSubmit">
            <div class="d-flex justify-content-center align-items-center">
              <b-spinner label="Loading..." class="mr-1"></b-spinner> Processing
            </div>
          </template>

          <template v-else>
            Save
          </template>
        </b-button>
      </div> -->

    </b-container>
    <!-- <b-modal hide-footer v-model="deleteModal">
      <p>Please confirm you want to delete this item? </p>

      <b-button class="mt-2 mr-1" @click="doDeleteItem">Yes</b-button>
      <b-button class="mt-2" @click="deleteModal = false">No</b-button>
    </b-modal> -->
  </b-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import axios from '@/axios';

export default {
  props: ['id'],
  data() {
    return {
      fields: [
        { key: 'itemModel', label: 'Model' },
        { key: 'serial', label: 'Serial' },
        { key: 'barcode', label: 'Barcode' },
        { key: 'sapDo', label: 'DO' },
        { key: 'warrantyStatus', label: 'Warrany Status' },
        { key: 'generalWarrantyPeriodEnd', label: 'General Warranty End' },
        { key: 'compressorWarrantyPeriodEnd', label: 'Compressor Warranty End' },
        { key: 'actions', label: 'Actions' }
      ],
      warrantyData: {},
      items: [],
      selectedItem: null,
      nonSaleItems: [],
      registerOptions: [
        { value: 'aircon', text: 'Air Conditioner' },
        { value: 'airpur', text: 'Air Purifier' },
      ],
      deleteModal: false,
      idTodelete: null,
      indexToDelete: 0,
      listSalutation: [
        { value: 'MR', text: 'Mr.' },
        { value: 'MS', text: 'Ms.' },
      ],
      model: '',
      serial: '',
      onSubmit: false,
      loadingWarranty: false,
    }
  },
  mounted() {
    axios
      .get(`/sales-and-purchase/drcc/warranty/detail/${this.$route.params.id}`)
      .then(res => {
        this.warrantyData = res.data.data
        console.log(this.warrantyData)
      })
      .catch(err => console.log({ err }))
  },
  computed: {
    isAirPurified() {
      let flag = this.warrantyData.purchaseDate !== undefined & this.warrantyData.purchaseDate !== null;
      if (flag) {
        return true;
      }

      return false;
    },
    warrantyErrorMessage: {
      get() {
        let { errorCode, errorStatus } = this.warrantyData;

        if (errorCode !== undefined || errorStatus !== undefined) {
          return `${errorCode} - ${errorStatus}`;
        }

        return ``
      },
      set(val) {
      }
    }
  },
  methods: {
    process() {
      if (this.onSubmit) {
        console.log('Still Processing Item')
        // Just Prevent Processing Logic To Continue
        return;
      }

      let rawData = JSON.stringify(this.warrantyData);
      let data = JSON.parse(rawData);

      if (data.registerType === 'airpur') {
        data.installerName = '';
        data.installationDate = '';
      } else {
        data.purchaseDate = '';
      }

      this.onSubmit = true;
      // let existing = this.getUsersBy(this.warrantyData.email).then((response)=> {
      //   this.$bvToast.toast('User Registered', {
      //         title: "Success",
      //         variant: "success",
      //         solid: true,
      //       });
            
      //     }).catch((e) => {
      //       console.log(e);
      //       this.$bvToast.toast(e.response.data.message, {
      //         title: "Failed",
      //         variant: "danger",
      //         solid: true,
      //       });
      // })
      // let fullname = `${this.warrantyData.nameFirst} ${this.warrantyData.nameLast}`
      // let customerData = {
      //   name: fullname,
      //   role:'Customer',
      //   email:this.warrantyData.email,
      //   password:'123456'
      // }
      // if(existing.length === 0) {
      //   this.addNewUser(customerData).then((response) => {
      //     this.$bvToast.toast('Add New user Successfully', {
      //         title: "Success",
      //         variant: "success",
      //         solid: true,
      //       });
      //     }).catch((e) => {
      //       console.log(e);
      //       this.$bvToast.toast(e.response.data.message, {
      //         title: "Failed",
      //         variant: "danger",
      //         solid: true,
      //       });
      //   })
      // } 

      this.editWarranty(this.id,data )
        .then(() => {
          // this.getWarrantyData();
          // this.getAllItems();

          this.$bvToast.toast("Successfully update Paperless Warranty", {
            title: "Success",
            variant: "success",
            solid: true,
          });

          this.onSubmit = false;
        })
        .catch((err) => {
          this.onSubmit = false;
          let errorMessage = err.response.data.message;

          if (errorMessage !== undefined) {
            this.$bvToast.toast(errorMessage, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast('Error Processing Data', {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        })
        // this.$router.go(-1)
      // alert(warrantyData)
    },
    getWarrantyData() {
      axios.get(`sales-and-purchase/drcc/external-warranty/detail/${this.$route.params.id}`).then((response) => {
        this.warrantyData = response.data.data;
        this.loadingWarranty = false;
      }).catch((e) => {
        console.log(e)
      })
    },
    getAllItems() {
      axios.get(`sales-and-purchase/drcc/postal/non-order-items`).then((response) => {
        this.nonSaleItems = response.data.data.map((item) => {
          return Object.assign({}, { value: item, text: `${item.itemModel} - ${item.barcode}` });
        })
      }).catch((e) => {
        console.log(e)
      })
    },
    addItem() {
      this.warrantyData.items.push(this.selectedItem);
    },
    deleteItem(row) {
      this.warrantyData.items.splice(row.index, 1);
    },
    confirmToDeleteItem(row) {
      this.deleteModal = true;
      this.idTodelete = row.item._id;
      this.indexToDelete = row.index;
    },
    deletePaperlessWarranty() {
      if (confirm('Please confirm you want to delete this draft warranty? ')) {
        this.deleteExternalWarranty(this.warrantyData._id).then((result) => {
          if (result) {
            this.$router.go(-1);
          }
        });
      }
    },
    doDeleteItem() {
      this.deleteWarrantyItemId({
        id: this.idTodelete,
        warrantyId: this.warrantyData._id,
      }).then(() => {
        this.warrantyData.items.splice(this.indexToDelete, 1);
        this.getWarrantyData();
        this.deleteModal = false;
      }).catch((e) => {
        this.deleteModal = false;
      })
    },
    completeRegister() {
      axios.post(`sales-and-purchase/drcc/submitted-warranty/complete`, this.warrantyData).then((response) => {
        this.$bvToast.toast('Register Complete', {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.getWarrantyData();
      }).catch((e) => {
        console.log(e)
        this.$bvToast.toast('Register Failed', {
          title: "Fail",
          variant: "danger",
          solid: true,
        });
      })
    },
    processWarranty() {
      axios.put(`sales-and-purchase/drcc/process/warranty`, this.warrantyData, { params: { type: 'External' }}).then((response) => {
        this.$bvToast.toast(`Process Warranty Registration Success`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }).catch((e) => {
        this.$bvToast.toast(`Process Warranty Registration Failed`, {
          title: "Fail",
          variant: "danger",
          solid: true,
        });
      })
    },
    manualAddItem() {
      axios.post(`sales-and-purchase/drcc/warranty/admin-verify`, {
        registerType: this.warrantyData.registerType,
        serial: this.serial,
        model: this.model,
      }).then((response) => {
        this.warrantyData.items.push(response.data.data);
        this.barcode = '';
      }).catch((e) => {
        this.$bvToast.toast(`${e.response.data.message}`, {
          title: "Fail",
          variant: "danger",
          solid: true,
        });
      })
    },
    resetItemStatus(id) {
      if (confirm('Are You Sure To Reset Equipment Warranty Status ?')) {
         axios.put('sales-and-purchase/drcc/reset-item-status', {
          itemId: id,
        }).then((response) => {
          // this.getWarrantyData();
          this.getAllItems();

          let items = JSON.parse(JSON.stringify(this.warrantyData.items));
          let itemIndex = items.findIndex(item => item._id === id);
          if (itemIndex > -1) {
            let item = items[itemIndex];
            item.warrantyStatus = 'Pre';
            items.splice(itemIndex, 1, item);
          }
          this.warrantyData = Object.assign({}, this.warrantyData, { items: items });

          this.$bvToast.toast(`Reset Item Warranty Status Success`, {
            title: "Success",
            variant: "success",
            solid: true,
          });
        }).catch((e) => {
          console.log(e)
          this.$bvToast.toast(`Reset Item Warranty Status Failed`, {
            title: "Fail",
            variant: "danger",
            solid: true,
          });
        })
      }
    },
    ...mapActions({
      deleteWarrantyItemId: 'warranty/deleteExternalWarrantyItem',
      deleteExternalWarranty: 'warranty/deleteExternalWarranty',
      getUsersBy: 'auth/getUsersBy',
      addNewUser: 'auth/newUser',
      editWarranty: 'warranty/updateWarranty'
    })
  }
}
</script>

<style scoped>
.section-data {
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
  margin: 20px 0 20px 0;
}

.section-data h5 {
  position: absolute;
  top: -10px;
  left: 6px;
  background: white;
  padding: 0 10px;
}
</style>